import { IAiEvaluationPayload } from '@libs/data/features/ai-evaluation/models/ai-evaluation.model';
import { StudentUnitContentAnswer } from '@libs/data/features/student/models/content/student-unit-content-answer.model';
import { StudentUnitContent } from '@libs/data/features/student/models/content/student-unit-content.model';
import { StudentUnit } from '@libs/data/features/student/models/student-unit.model';
import { IVideoTrackingPayload } from '@libs/data/features/student/models/video-tracking.model';
import { Unit } from '@libs/data/features/unit/models/unit.model';
import { createAction, props } from '@ngrx/store';
import { ViewerScope } from '../../enums/viewer-scope.enum';
import { ViewerLesson } from '../../types/viewer-lesson.type';

export enum ViewerActionType {
  GET_UNIT_BY_ID = '[Viewer] Getting unit by ID...',
  GET_UNIT_BY_ID_SUCCESS = '[Viewer] Get unit by ID success',
  GET_LESSON_BY_ID = '[Viewer] Getting lesson by ID...',
  GET_LESSON_BY_ID_SUCCESS = '[Viewer] Get lesson by ID success',
  SET_STUDENT_LESSON_CONTENT_ANSWER = '[Viewer] Updating lesson content by ID...',
  SET_UPDATED_LESSON_CONTENT = '[Viewer] Setting updated lesson content',
  CLEAR_UNIT = '[Viewer] Clear unit',
  UPDATE_VIDEO_TRACKING = '[Viewer] Updating video tracking...',
  SET_EXITED_FROM_LESSON_CONTENTS = '[Viewer] Setting exited first lesson contents',
  GET_AI_EVALUATION = '[AI EVALUATION] Get AI evaluation...',
  GET_AI_EVALUATION_SUCCESS = '[AI EVALUATION] Get AI evaluation success',
}

export const getUnitById = createAction(
  ViewerActionType.GET_UNIT_BY_ID,
  props<{ unitId: string; scope: ViewerScope }>(),
);
export const getUnitByIdSuccess = createAction(
  ViewerActionType.GET_UNIT_BY_ID_SUCCESS,
  props<{ unit: StudentUnit | Unit }>(),
);

export const getLessonById = createAction(ViewerActionType.GET_LESSON_BY_ID, props<{ lessonId: string }>());
export const getLessonByIdSuccess = createAction(
  ViewerActionType.GET_LESSON_BY_ID_SUCCESS,
  props<{ lesson: ViewerLesson }>(),
);

export const setStudentLessonContentAnswer = createAction(
  ViewerActionType.SET_STUDENT_LESSON_CONTENT_ANSWER,
  props<{ unitId: string; lessonId: string; contentId: string; answerPayload: StudentUnitContentAnswer }>(),
);

export const setUpdatedLessonContent = createAction(
  ViewerActionType.SET_UPDATED_LESSON_CONTENT,
  props<{ contentId: string; content: StudentUnitContent }>(),
);

export const clearUnit = createAction(ViewerActionType.CLEAR_UNIT);

export const updateVideoTracking = createAction(
  ViewerActionType.UPDATE_VIDEO_TRACKING,
  props<{ payload: IVideoTrackingPayload }>(),
);

export const setExitedFromLessonContents = createAction(
  ViewerActionType.SET_EXITED_FROM_LESSON_CONTENTS,
  props<{
    exited: boolean;
  }>(),
);

export const getAiEvaluation = createAction(
  ViewerActionType.GET_AI_EVALUATION,
  props<{ data: IAiEvaluationPayload }>(),
);

export const getAiEvaluationSuccess = createAction(
  ViewerActionType.GET_AI_EVALUATION_SUCCESS,
  props<{ evaluation: string }>(),
);
