import { Injectable } from '@angular/core';

import * as Sentry from '@sentry/angular';

export interface SentryMessageOptions {
  tag?: Array<{
    name: string;
    value: string;
  }>;
  context?: Array<{
    name: string;
    value: Record<string, unknown>;
  }>;
}

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  public setUser(identifiers: { id: string; email: string } | null) {
    Sentry.setUser(identifiers ? { id: identifiers.id, email: identifiers.email } : null);
  }

  public logMessage(message: string, options?: SentryMessageOptions): void {
    Sentry.captureMessage(message, (scope) => {
      scope.clear();

      if (options?.tag) {
        options.tag.forEach(({ name, value }) => {
          scope.setTag(name, value);
        });
      }

      if (options?.context) {
        options.context.forEach(({ name, value }) => {
          scope.setContext(name, value);
        });
      }

      return scope;
    });
  }
}
