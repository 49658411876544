export enum UnitVersioningMode {
  SAVE = 'save',
  CREATE_VERSION = 'create_version',
  SAVE_AND_RESET_STATS = 'save_and_reset_stats',
}

export enum UnitVersioningAction {
  DELETE = 'delete',
  CREATE = 'create',
  EDIT = 'edit',
}
