import { ContentType } from '@libs/data/features/unit/enums/content-type.enum';
import { UnitVersioningAction, UnitVersioningMode } from '@libs/data/features/unit/enums/unit-versioning-mode.enum';
import { ContentFactory } from '@libs/data/features/unit/factories/content.factory';
import { IContent } from '@libs/data/features/unit/models/content/content.model';
import { IUpdateContentPayload } from '@libs/data/features/unit/models/content/update-content-payload.model';
import { ILesson, Lesson } from '@libs/data/features/unit/models/lesson.model';
import { Unit } from '@libs/data/features/unit/models/unit.model';
import { IUpdateLessonPayload, IUpdateUnitPayload } from '@libs/data/features/unit/models/update-unit-payload.model';
import { StudentUnit } from '../../../data/src/features/student/models/student-unit.model';

export const getUnitVersioningModeOptions = (
  pendingAction: {
    action: UnitVersioningAction;
    entity: 'content' | 'lesson' | 'unit';
    type?: ContentType;
  },
  enableCreateVersion?: boolean,
) => {
  const mappedEntityActions = [
    {
      actions: [UnitVersioningAction.EDIT],
      types: [],
      entity: 'unit',
      options: [UnitVersioningMode.SAVE, ...(enableCreateVersion ? [UnitVersioningMode.CREATE_VERSION] : [])],
    },
    {
      actions: [UnitVersioningAction.DELETE, UnitVersioningAction.CREATE],
      types: [],
      entity: 'lesson',
      options: [UnitVersioningMode.CREATE_VERSION],
    },
    {
      actions: [UnitVersioningAction.EDIT],
      types: [],
      entity: 'lesson',
      options: [UnitVersioningMode.SAVE, ...(enableCreateVersion ? [UnitVersioningMode.CREATE_VERSION] : [])],
    },
    {
      actions: [UnitVersioningAction.CREATE, UnitVersioningAction.DELETE],
      types: [
        ContentType.EXERCISE,
        ContentType.SURVEY,
        ContentType.TEST,
        ContentType.EXAMPLE,
        ContentType.FILE,
        ContentType.LINK,
        ContentType.VIDEO,
      ],
      entity: 'content',
      options: [
        UnitVersioningMode.SAVE_AND_RESET_STATS,
        ...(enableCreateVersion ? [UnitVersioningMode.CREATE_VERSION] : []),
      ],
    },
    {
      actions: [UnitVersioningAction.EDIT],
      types: [ContentType.EXERCISE, ContentType.SURVEY, ContentType.TEST],
      entity: 'content',
      options: [
        UnitVersioningMode.SAVE_AND_RESET_STATS,
        UnitVersioningMode.SAVE,
        ...(enableCreateVersion ? [UnitVersioningMode.CREATE_VERSION] : []),
      ],
    },
    {
      actions: [UnitVersioningAction.EDIT],
      types: [ContentType.EXAMPLE, ContentType.FILE, ContentType.LINK, ContentType.VIDEO],
      entity: 'content',
      options: [UnitVersioningMode.SAVE, ...(enableCreateVersion ? [UnitVersioningMode.CREATE_VERSION] : [])],
    },
  ];

  return mappedEntityActions.find((action) => {
    return (
      action.entity === pendingAction.entity &&
      action.actions.includes(pendingAction.action) &&
      (pendingAction.type ? action.types.includes(pendingAction.type) : true)
    );
  }) as (typeof mappedEntityActions)[number];
};

export const getUpdatedUnitPayload = (
  action: UnitVersioningAction,
  unit: Unit,
  payload: IUpdateLessonPayload | IUpdateContentPayload | IUpdateUnitPayload,
  lessonId?: string,
  contentId?: string,
) => {
  let lessons: Lesson[];

  if (!contentId && !payload) {
    lessons = getUpdatedUnitLessons(unit.lessons, action, payload as IUpdateLessonPayload, lessonId);
  } else if (payload && isUpdateLessonPayload(payload)) {
    lessons = getUpdatedUnitLessons(unit.lessons, action, payload as IUpdateLessonPayload, lessonId);
  } else if ((contentId && !payload) || (payload && isUpdateContentPayload(payload))) {
    lessons = getUpdatedContent(unit.lessons, action, payload as IUpdateContentPayload, lessonId, contentId);
  } else {
    lessons = unit.lessons;
  }

  return {
    ...unit,
    owner: unit.owner?.id,
    type: unit.type?.id,
    lessons: lessons,
    ...(isUpdateUnitPayload(payload) && payload),
  };
};

export const getUpdatedContent = (
  lessons: Lesson[],
  action: UnitVersioningAction,
  payload: IUpdateContentPayload,
  lessonId?: string,
  contentId?: string,
): Lesson[] => {
  return lessons.map((lesson) => {
    if (lesson.id !== lessonId) {
      return lesson;
    }

    if (action === UnitVersioningAction.CREATE) {
      const newLesson = { ...lesson };

      newLesson.contents = [...lesson.contents, ContentFactory.createContent(payload as IContent)];

      return newLesson;
    }

    if (action === UnitVersioningAction.EDIT) {
      const newLesson = { ...lesson };

      newLesson.contents = lesson.contents.map((content) => {
        if (content.id !== contentId) {
          return content;
        }

        return { ...content, ...payload };
      });

      return newLesson;
    }

    if (action === UnitVersioningAction.DELETE) {
      const newLesson = { ...lesson };

      newLesson.contents = lesson.contents.filter((content) => content.id !== contentId);

      return newLesson;
    }

    return lesson;
  });
};

export const getUpdatedUnitLessons = (
  lessons: Lesson[],
  action: UnitVersioningAction,
  payload: IUpdateLessonPayload,
  lessonId?: string,
): Lesson[] => {
  let updatedLessons: Lesson[] = [];

  if (UnitVersioningAction.EDIT) {
    updatedLessons = lessons.map((lesson) => {
      if (lesson.id === lessonId) {
        return { ...lesson, ...payload };
      }

      return lesson;
    });
  }

  if (action === UnitVersioningAction.CREATE) {
    updatedLessons = [...updatedLessons, Lesson.Build(payload as ILesson)];
  }

  if (action === UnitVersioningAction.DELETE) {
    updatedLessons = updatedLessons.filter(({ id }) => id !== lessonId);
  }

  return updatedLessons;
};

export const isUpdateContentPayload = (
  payload: IUpdateContentPayload | IUpdateLessonPayload | IUpdateUnitPayload,
): payload is IUpdateContentPayload => payload && !('owner' in payload) && 'type' in payload;

export const isUpdateLessonPayload = (
  payload: IUpdateContentPayload | IUpdateLessonPayload | IUpdateUnitPayload,
): payload is IUpdateLessonPayload => payload && !('owner' in payload) && !('type' in payload);

export const isUpdateUnitPayload = (
  payload: IUpdateContentPayload | IUpdateLessonPayload | IUpdateUnitPayload,
): payload is IUpdateUnitPayload => payload && 'owner' in payload;

export const isClassUnit = (unit: Unit | StudentUnit): unit is StudentUnit =>
  (unit as StudentUnit).parentContentType === 'class';
export const isCourseUnit = (unit: Unit | StudentUnit): unit is StudentUnit =>
  (unit as StudentUnit).parentContentType === 'course';
export const getUnitType = (unit: Unit | StudentUnit): 'class' | 'course' | 'unit' => {
  return isCourseUnit(unit) && unit.parentContentId
    ? 'course'
    : isClassUnit(unit) && unit.parentContentId
      ? 'class'
      : 'unit';
};
