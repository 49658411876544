<div [ngClass]="{ show: showMenu }" id="menu-mobile-top-bar">
  <a class="close-mobile m-0 p-2" (click)="handleShowMenu.emit(false)">
    <img class="icon" src="assets/images/icons/close.svg" alt="Close icon" />
  </a>
  <div class="menu-items d-flex flex-column justify-content-between">
    <div>
      <div class="d-flex align-items-center">
        <ui-kit-user-avatar [currentUser]="currentUser"></ui-kit-user-avatar>
        <!-- <ngb-accordion #acc="ngbAccordion" class="application-top-bar">
          <ngb-panel id="0">
            <ng-template ngbPanelTitle>
              <div class="d-flex justify-content-between align-items-center">
                <ui-kit-user-avatar [currentUser]="currentUser"></ui-kit-user-avatar>
                <a class="ml-4 mr-3" [ngClass]="{ 'cursor-not-allowed': currentUser.role !== role.STUDENT }">{{
                  "Profile" | i18nextEager
                }}</a>
                <img
                  [src]="
                    acc.isExpanded('0') ? 'assets/images/icons/arrow-up.svg' : 'assets/images/icons/arrow-down.svg'
                  "
                  alt="Dropdown menu"
                />
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div>
                <img src="assets/images/icons/settings.svg" alt="Settings" />
                <a class="ml-3" (click)="redirectToSettings.emit(); handleShowMenu.emit(false)">
                  {{ "Settings" | i18nextEager }}
                </a>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion> -->
      </div>

      <div *ngIf="topbarUrls.notion" class="d-flex align-items-center mt-4">
        <a [href]="topbarUrls.notion" target="_blank" (click)="handleShowMenu.emit(false)" rel="noopener">{{
          "application.Courses.TopBarLabels.Notion" | i18nextEager
        }}</a>
      </div>
      <div class="d-flex align-items-center mt-4">
        <a [href]="topbarUrls.faq" (click)="this.handleShowMenu.emit(false)" target="_blank" rel="noopener">{{
          "application.Courses.TopBarLabels.Faq" | i18nextEager
        }}</a>
      </div>
      <div *ngIf="topbarUrls.career" class="d-flex align-items-center mt-4">
        <a [href]="topbarUrls.career" target="_blank" (click)="handleShowMenu.emit(false)" rel="noopener">
          {{ "application.Courses.TopBarLabels.Career" | i18nextEager }}
        </a>
      </div>

      <div class="d-flex align-items-center mt-4 border-top pt-4 cursor-pointer">
        <img class="icon" src="assets/images/icons/language.svg" alt="language" />
        <div class="d-flex w-100 justify-content-between" (click)="showLanguages = true">
          <span>{{ currentUser.language | langExtend }}</span>
          <img class="lang-arrow-forward" src="assets/images/icons/arrow-forward-black.svg" alt="forward" />
        </div>
      </div>
      <div id="menu-mobile-lang" [ngClass]="{ show: showLanguages }" class="p-4">
        <img
          class="cursor-pointer"
          src="assets/images/icons/arrow-back-black.svg"
          alt="back"
          (click)="showLanguages = false"
        />
        <div class="pl-3">
          <span class="my-4 d-block">{{ "application.Courses.TopBarLabels.Language" | i18nextEager }}</span>
          <p *ngFor="let language of languages" class="pl-5 cursor-pointer" (click)="setLanguage.emit(language)">
            {{ language | langExtend }}
          </p>
        </div>
      </div>

      <div class="other-links mt-4">
        <a routerLink="/dashboard/homepage" (click)="handleShowMenu.emit(false)">Home</a>
        <ng-container *ngFor="let link of otherLinks; let i = index">
          <ng-container *authPermission="{ feature: link.feature, actions: link.actions }">
            <a
              class="d-flex align-items-center mt-4 link-container"
              *ngIf="!link.subLinks?.length"
              (click)="handleShowMenu.emit(false)"
              [routerLink]="link.path"
            >
              <span>{{ link.name | i18nextEager: { count: 0 } }}</span>
            </a>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <a class="mt-2" (click)="logout.emit()">
      <img class="icon" src="assets/images/icons/log-out-black.svg" alt="Logout" />
      <span>{{ "Exit" | i18nextEager }}</span>
    </a>
  </div>
</div>
