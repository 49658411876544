import { Inject, Injectable } from '@angular/core';
import * as AuthActions from '@libs/auth/store/actions/auth.actions';
import { LocalStorageKey } from '@libs/data/enums/local-storage-key.enum';
import { Staff } from '@libs/data/features/staff/models/staff.model';
import { ISessionTimeTrackingModel } from '@libs/data/features/student/models/session-time-tracking.model';
import { Student } from '@libs/data/features/student/models/student.model';
import { StudentService } from '@libs/data/features/student/services/student.service';
import { UserAgentService } from '@libs/data/features/student/services/user-agent.service';
import { Language } from '@libs/data/features/user/models/language.model';
import { IUser } from '@libs/data/features/user/models/user.model';
import { UserService } from '@libs/data/features/user/services/user.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { I18NEXT_SERVICE, I18NextPipe, ITranslationService } from 'angular-i18next';
import * as dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, interval, of } from 'rxjs';
import { concatMap, map, switchMap, take, tap } from 'rxjs/operators';
import { CacheService } from '../../services/cache/cache.service';
import { isClassUnit, isCourseUnit } from '../../utils/unit';
import { parseUrl } from '../../utils/url';
import * as SharedActions from '../actions/shared.actions';
import { SharedFacade } from '../facades/shared.facade';
import { FirebaseService } from './../../../../auth/src/firebase/firebase.service';
import { ViewerFacade } from './../../../../viewer/src/store/facades/viewer.facade';

@Injectable()
export class SharedEffects extends CacheService {
  public showSnackbar$ = createEffect(() =>
    this.action$.pipe(
      ofType(SharedActions.showSnackBar),
      map(({ snackBarConfig }) => {
        const message = this.i18nextPipe.transform(snackBarConfig.message);

        switch (snackBarConfig.type) {
          case 'info':
            return this.toastrService.info(message, snackBarConfig.title, snackBarConfig.options);
          case 'success':
            return this.toastrService.success(message, snackBarConfig.title, snackBarConfig.options);
          case 'error':
            return this.toastrService.error(message, snackBarConfig.title, snackBarConfig.options);
          default:
            return this.toastrService.info(message, snackBarConfig.title, snackBarConfig.options);
        }
      }),
      map(() => SharedActions.showSnackBarSuccess()),
    ),
  );

  public setLanguage$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(SharedActions.setLanguage),
        map(({ language }) => {
          this.i18NextService.changeLanguage(language);
          localStorage.setItem('language', language);
          this.firebaseService.setLanguage(language);
        }),
      ),
    { dispatch: false },
  );

  public updateUserLanguage$ = createEffect(() =>
    this.action$
      .pipe(
        ofType(SharedActions.updateUserLanguage),
        tap(() => this.sharedFacade.setGlobalLoader()),
        switchMap(({ language }) => this.userService.updateMe({ language } as IUser)),
      )
      .pipe(
        tap(() => this.sharedFacade.setGlobalLoader(false)),
        switchMap((user: Student | Staff) => {
          return [
            SharedActions.setLanguage({ language: user.language as Language }),
            AuthActions.updateCurrentUser({ user }),
          ];
        }),
      ),
  );

  public setRedirectUrl$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(SharedActions.setRedirectUrl),
        map(({ url }) => {
          const parsedUrl = parseUrl(url);
          const whitelistUrls = new Set(['/login', '/sso', '/']);

          if (!whitelistUrls.has(parsedUrl)) {
            localStorage.setItem(LocalStorageKey.REDIRECT_URL, parsedUrl);
          }
        }),
      ),
    { dispatch: false },
  );

  public startUserSessionTimeTracking$ = createEffect(() =>
    this.action$.pipe(
      ofType(SharedActions.startUserSessionTimeTracking),
      switchMap(({ delay }) =>
        interval(delay).pipe(
          switchMap(() => {
            return this.viewerFacade.viewerState$.pipe(
              take(1),
              switchMap((viewerState) => {
                const deviceInfo = this.userAgentService.getSessionInfo();
                const timeTrackingCachedMap = this.getItem(LocalStorageKey.SESSION_TIME_TRACKING);
                const timeTrackingMap = new Map<string, ISessionTimeTrackingModel>(
                  timeTrackingCachedMap ? JSON.parse(timeTrackingCachedMap) : [],
                );
                const todayDate = dayjs();
                const today = todayDate.format('YYYY-MM-DD');
                const timeTrackingItem = timeTrackingMap.get(today);
                const type = viewerState?.type;
                const unit = viewerState?.unit.data;

                if (type && unit) {
                  const unitId = unit.id;
                  const classId = isClassUnit(unit) && unit.parentContentId ? unit.parentContentId : undefined;
                  const courseId = isCourseUnit(unit) && unit.parentContentId ? unit.parentContentId : undefined;

                  if (timeTrackingItem) {
                    const { sessionTime, date } = timeTrackingItem;

                    timeTrackingMap.set(today, {
                      ...deviceInfo,
                      date,
                      type,
                      unitId,
                      classId,
                      courseId,
                      sessionTime: sessionTime + delay,
                    });
                  } else {
                    timeTrackingMap.set(today, {
                      ...deviceInfo,
                      date: todayDate.format(),
                      sessionTime: delay,
                      type,
                      unitId,
                      classId,
                      courseId,
                    });
                  }

                  const timeTrackingEntries = Array.from(timeTrackingMap.entries());
                  this.setItem(LocalStorageKey.SESSION_TIME_TRACKING, timeTrackingEntries);

                  return timeTrackingEntries.map(([key, value]) =>
                    SharedActions.updateUserSessionTimeTracking({
                      date: key,
                      timeTrackingItem: value,
                      timeTrackingMap,
                    }),
                  );
                }

                return EMPTY;
              }),
            );
          }),
        ),
      ),
    ),
  );

  public updateUserSessionTimeTracking$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(SharedActions.updateUserSessionTimeTracking),
        concatMap(({ date, timeTrackingItem, timeTrackingMap }) => {
          return (!timeTrackingItem.type ? of({}) : this.studentService.updateSession(timeTrackingItem)).pipe(
            tap(() => {
              timeTrackingMap.delete(date);
              this.setItem(LocalStorageKey.SESSION_TIME_TRACKING, Array.from(timeTrackingMap.entries()));
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
    private readonly i18nextPipe: I18NextPipe,
    private readonly action$: Actions,
    private readonly userService: UserService,
    private readonly toastrService: ToastrService,
    private readonly sharedFacade: SharedFacade,
    private readonly userAgentService: UserAgentService,
    private readonly studentService: StudentService,
    private readonly firebaseService: FirebaseService,
    private readonly viewerFacade: ViewerFacade,
  ) {
    super();
  }
}
