import { ClassTag } from '../../class-tags/models/class-tag.model';
import { ClassType } from '../../class-types/models/class-type.model';
import { ClassEffort } from '../../class/enums/class-effort.enum';
import { ClassStatus } from '../../class/enums/class-status.enum';
import { Staff } from '../../staff/models/staff.model';
import { StudentDashboardStats } from '../../student/models/student-dashboard-stats.model';
import { Student } from '../../student/models/student.model';
import { StudentDashboardClassAction } from '../enums/student-dashboard-class-action.enum';
import { IStudentDashboardUnit, StudentDashboardUnit } from './student-dashboard-unit.model';

export interface IStudentDashboardClass {
  id: string;
  name: string;
  type: ClassType;
  managedBy: Staff;
  startDate: Date;
  template: string;
  numberOfStudents: number;
  students: Student[];
  units: IStudentDashboardUnit[];
  createdAt: string;
  updatedAt: string;
  status: ClassStatus;
  effort: ClassEffort;
  tags: ClassTag[];
  stats: StudentDashboardStats;
  action: StudentDashboardClassAction;
  showProgress: boolean;
  graduatedAt?: Date;
}

export class StudentDashboardClass {
  constructor(
    public id: string,
    public name: string,
    public type: ClassType,
    public managedBy: Staff,
    public startDate: Date,
    public template: string,
    public numberOfStudents: number,
    public students: Student[],
    public units: StudentDashboardUnit[],
    public createdAt: string,
    public updatedAt: string,
    public status: ClassStatus,
    public effort: ClassEffort,
    public tags: ClassTag[],
    public stats: StudentDashboardStats,
    public action: StudentDashboardClassAction,
    public showProgress: boolean,
    public graduatedAt?: Date,
  ) {}
  public static Build(c: IStudentDashboardClass): StudentDashboardClass {
    const managedBy = Staff.Build(c.managedBy);
    const units = (c.units || []).map((unit) => StudentDashboardUnit.Build(unit));

    return new StudentDashboardClass(
      c.id,
      c.name,
      c.type,
      managedBy,
      new Date(c.startDate),
      c.template,
      c.numberOfStudents,
      c.students,
      units,
      c.createdAt,
      c.updatedAt,
      c.status,
      c.effort,
      c.tags,
      c.stats,
      c.action,
      c.showProgress,
      c.graduatedAt ? new Date(c.graduatedAt) : undefined,
    );
  }
}
