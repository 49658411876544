import { StudentUnitStatus } from '../enums/student-unit-status.enum';
import { StudentDashboardUnitAction } from './../enums/student-dashboard-unit-action.enum';
import { StudentDashboardStats } from './student-dashboard-stats.model';

export interface IStudentDashboardUnit {
  id: string;
  type: string;
  name: string;
  language: string;
  status: StudentUnitStatus;
  duration: number;
  stats: StudentDashboardStats;
  index: number;
  action: StudentDashboardUnitAction;
  showProgress: boolean;
  blocked?: boolean;
}

export class StudentDashboardUnit {
  constructor(
    public id: string,
    public type: string,
    public name: string,
    public language: string,
    public status: StudentUnitStatus,
    public duration: number,
    public stats: StudentDashboardStats,
    public index: number,
    public action: StudentDashboardUnitAction,
    public showProgress: boolean,
    public blocked?: boolean,
  ) {}

  public static Build(unit: IStudentDashboardUnit): StudentDashboardUnit {
    return new StudentDashboardUnit(
      unit.id,
      unit.type,
      unit.name,
      unit.language,
      unit.status,
      unit.duration,
      unit.stats,
      unit.index,
      unit.action,
      unit.showProgress,
      unit.blocked,
    );
  }
}
