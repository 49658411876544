import { Injectable } from '@angular/core';
import { IClassUnitStudent } from '@staff-platform/app/shared/models/class-unit-student.model';
import { Observable, map } from 'rxjs';
import { PaginatedModel } from '../../../models/pagination.model';
import { Unit } from '../../unit/models/unit.model';
import { StudentContentFactory } from '../factories/student-unit-content.factory';
import { StudentUnitContentAnswer } from '../models/content/student-unit-content-answer.model';
import { StudentUnitContent } from '../models/content/student-unit-content.model';
import { ISessionTimeTrackingModel } from '../models/session-time-tracking.model';
import { StudentDashboardClass } from '../models/student-dashboard-class.model';
import { StudentDashboardContentPreview } from '../models/student-dashboard-content-preview.model';
import { StudentDashboardCourse } from '../models/student-dashboard-course.model';
import { StudentDashboardUnit } from '../models/student-dashboard-unit.model';
import { StudentDashboard } from '../models/student-dashboard.model';
import { StudentUnitType } from '../models/student-unit-type.model';
import { StudentUnit } from '../models/student-unit.model';
import { IStudentUnitsPayload } from '../models/sudents-units-payload.model';
import { IVideoTrackingPayload } from '../models/video-tracking.model';
import { V1StudentRepository } from '../repositories/v1/student.repository';

@Injectable({ providedIn: 'root' })
export class StudentService {
  constructor(private readonly studentRepository: V1StudentRepository) {}

  public getStudentUnitTypes(page?: number, pagelen?: number): Observable<PaginatedModel<StudentUnitType>> {
    return this.studentRepository.getStudentUnitTypes(page, pagelen).pipe(
      map((response) => ({
        ...response,
        data: response.data.map((unitType) => StudentUnitType.Build(unitType)),
      })),
    );
  }

  public getUnitsOfStudent(
    unitTypeId: string,
    page?: number,
    pagelen?: number,
  ): Observable<PaginatedModel<StudentDashboardUnit>> {
    return this.studentRepository.getUnitsOfStudent(unitTypeId, page, pagelen).pipe(
      map((response) => ({
        ...response,
        data: response.data.map((unit) => StudentDashboardUnit.Build(unit)),
      })),
    );
  }

  public getStudentUnitById(unitId: string): Observable<StudentUnit> {
    return this.studentRepository.getStudentUnitById(unitId).pipe(map((studentUnit) => StudentUnit.Build(studentUnit)));
  }

  public getStudentDashboard(): Observable<StudentDashboard> {
    return this.studentRepository.getStudentDashboard().pipe(
      map((response) => {
        return {
          units: response.units.map((unit) => StudentDashboardUnit.Build(unit)),
          classes: response.classes.map((cls) => StudentDashboardClass.Build(cls)),
          courses: response.courses,
          profile: response.profile,
          available: response.available,
        };
      }),
    );
  }

  public getStudentDashboardClass(classId: string): Observable<StudentDashboardClass> {
    return this.studentRepository
      .getStudentDashboardClass(classId)
      .pipe(map((cls) => StudentDashboardClass.Build(cls)));
  }

  public getStudentDashboardCourse(courseId: string): Observable<StudentDashboardCourse> {
    return this.studentRepository.getStudentDashboardCourse(courseId);
  }

  public signupToCourse(courseId: string): Observable<StudentDashboardCourse> {
    return this.studentRepository.signupToCourse(courseId);
  }

  public getStudentDashboardContent(contentId: string): Observable<StudentDashboardContentPreview> {
    return this.studentRepository.getStudentDashboardContent(contentId);
  }

  public getStudentUnits(
    studentId: string,
    page?: number,
    pagelen?: number,
  ): Observable<PaginatedModel<IClassUnitStudent>> {
    return this.studentRepository.getStudentUnits(studentId, page, pagelen);
  }

  public saveStudentUnitLessonContentAnswer(
    unitId: string,
    lessonId: string,
    contentId: string,
    answerPayload: StudentUnitContentAnswer,
  ): Observable<StudentUnitContent> {
    return this.studentRepository
      .saveStudentUnitLessonContentAnswer(unitId, lessonId, contentId, answerPayload)
      .pipe(map((response) => StudentContentFactory.createContent(response.answer)));
  }

  public removeUnit(studentId: string, params: IStudentUnitsPayload): Observable<void> {
    return this.studentRepository.removeUnit(studentId, params);
  }

  public addUnits(studentId: string, params: IStudentUnitsPayload): Observable<void> {
    return this.studentRepository.addUnits(studentId, params);
  }

  public getUnlockableUnits(studentId: string, search: string): Observable<PaginatedModel<Unit>> {
    return this.studentRepository.getUnlockableUnits(studentId, search).pipe(
      map((response) => ({
        ...response,
        data: response.data.map((unit) => Unit.Build(unit)),
      })),
    );
  }

  public updateSession(sessionInfo: ISessionTimeTrackingModel): Observable<ISessionTimeTrackingModel> {
    return this.studentRepository.updateSession(sessionInfo);
  }

  public updateVideoTracking(videoTrackingPayload: IVideoTrackingPayload): Observable<IVideoTrackingPayload> {
    return this.studentRepository.updateVideoTracking(videoTrackingPayload);
  }

  public getImpersonationToken(userId: string): Observable<{ token: string }> {
    return this.studentRepository.getImpersonationToken(userId);
  }
}
