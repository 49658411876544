import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Staff } from '@libs/data/features/staff/models/staff.model';
import { Student } from '@libs/data/features/student/models/student.model';
import { Role } from '@libs/data/features/user/enums/role.enum';
import { getSupportedLanguages } from '@libs/data/features/user/models/language.model';
import { ITopBarLink } from '../../models/top-bar-link.model';

@Component({
  selector: 'ui-kit-top-bar-mobile',
  templateUrl: './top-bar-mobile.component.html',
  styleUrls: ['./top-bar-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarMobileComponent {
  @Input() showMenu = false;
  @Input() currentUser = {} as Student | Staff;
  @Input() otherLinks: ITopBarLink[] = [];
  @Input() topbarUrls = { faq: '', notion: '', career: '' };

  @Output() logout = new EventEmitter<void>();
  @Output() redirectToHome = new EventEmitter<void>();
  @Output() redirectToSettings = new EventEmitter<void>();
  @Output() handleShowMenu = new EventEmitter<boolean>();
  @Output() setLanguage = new EventEmitter<string>();

  public languages = getSupportedLanguages();
  public showLanguages = false;
  public role = Role;
}
