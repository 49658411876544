import { Injectable } from '@angular/core';
import { CacheService } from '@libs/common/services/cache/cache.service';
import { SentryService } from '@libs/common/services/sentry/sentry.service';
import { isStaffPlatform, isStudentsPlatform } from '@libs/common/utils/url';
import { isStudent } from '@libs/common/utils/user';
import { Staff } from '@libs/data/features/staff/models/staff.model';
import { Student } from '@libs/data/features/student/models/student.model';
import { Role } from '@libs/data/features/user/enums/role.enum';
import { IUserSignupPayload } from '@libs/data/features/user/models/user.model';
import { V1AuthRepository } from '@libs/data/features/user/repositories/v1/auth.repository';
import firebase from 'firebase/app';
import { Observable, of, throwError, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirebaseService } from '../firebase/firebase.service';
import { RedirectService } from './redirect.service';

export type LogoutMode = 'hard' | 'soft';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends CacheService {
  constructor(
    private readonly redirectService: RedirectService,
    private readonly firebaseService: FirebaseService,
    private readonly authRepository: V1AuthRepository,
    private readonly sentryService: SentryService,
  ) {
    super();
  }

  public me(): Observable<Student | Staff> {
    return this.authRepository.me().pipe(
      tap((response) =>
        this.sentryService.setUser({
          id: response.id,
          email: response.email,
        }),
      ),
      map((response) => {
        if (response.role === Role.STUDENT) {
          return Student.Build(response);
        } else {
          return Staff.Build(response);
        }
      }),
    );
  }

  public async login(email: string, password: string): Promise<Observable<Student | Staff>> {
    try {
      await this.firebaseService.signIn({ email, password });
      return this.me();
    } catch (error) {
      return throwError(() => null);
    }
  }

  public async loginWithCustomToken(customToken: string): Promise<Observable<Student | Staff>> {
    try {
      await this.firebaseService.signInWithCustomToken(customToken);
      return this.me();
    } catch (error) {
      return throwError(() => null);
    }
  }

  public signUp(payload: IUserSignupPayload): Observable<Student> {
    return this.authRepository.signUp(payload).pipe(map((user) => Student.Build(user)));
  }

  public redirectAfterLogin(user: Staff | Student): void {
    if ((isStudentsPlatform() && !isStudent(user.role)) || (isStaffPlatform() && isStudent(user.role))) {
      this.logout('soft');
    }

    this.redirectService.getRedirectLink(user.role);
  }

  public authLoginWithSocial(provider: firebase.auth.AuthProvider): Promise<firebase.auth.UserCredential> {
    return this.firebaseService.signInWithSocial(provider);
  }

  public async sendResetPasswordEmail(email: string): Promise<Observable<boolean>> {
    try {
      await this.firebaseService.sendResetPasswordEmail(email);
      return of(true);
    } catch (error) {
      return throwError(() => null);
    }
  }

  public logout(mode: LogoutMode = 'hard'): void {
    this.firebaseService.logout();
    this.sentryService.setUser(null);

    if (mode === 'hard') {
      localStorage.clear();
      location.href = '/login';
    }
  }
}
