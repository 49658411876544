import { SharedAppState } from '@libs/common/store/store.interface';
import { createSelector } from '@ngrx/store';

export const selectViewerState = (state: SharedAppState) => state.viewer;

export const isViewerPreviewMode = createSelector(selectViewerState, ({ isPreviewMode }) => isPreviewMode);
export const viewerUnit = createSelector(selectViewerState, ({ unit }) => unit);
export const viewerLesson = createSelector(selectViewerState, ({ lesson }) => lesson);
export const exitedFromLessonContents = createSelector(
  selectViewerState,
  ({ exitedFromLessonContents }) => exitedFromLessonContents,
);
export const aiEvaluation = createSelector(selectViewerState, ({ aiEvaluation }) => aiEvaluation);
export const type = createSelector(selectViewerState, ({ type }) => type);
