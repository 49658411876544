import { ClassTag } from '../../class-tags/models/class-tag.model';
import { ClassType } from '../../class-types/models/class-type.model';
import { Staff } from '../../staff/models/staff.model';
import { Student } from '../../student/models/student.model';
import { IUnit } from '../../unit/models/unit.model';
import { ClassEffort } from '../enums/class-effort.enum';
import { ClassStatus } from '../enums/class-status.enum';

export interface IClass {
  id: string;
  name: string;
  type: ClassType;
  managedBy: Staff;
  startDate: Date;
  template: string;
  numberOfStudents: number;
  students: Student[];
  units: Partial<IUnit>[];
  createdAt: string;
  updatedAt: string;
  status: ClassStatus;
  effort: ClassEffort;
  tags: ClassTag[];
  graduatedAt?: Date;
}

export interface IClassStudentsUnitUnlocked {
  numberOfStudentsUnlockedUnit: number;
}

export class Class {
  constructor(
    public id: string,
    public name: string,
    public type: ClassType,
    public managedBy: Staff,
    public startDate: Date,
    public template: string,
    public numberOfStudents: number,
    public students: Student[],
    public units: Partial<IUnit>[],
    public createdAt: string,
    public updatedAt: string,
    public status: ClassStatus,
    public effort: ClassEffort,
    public tags: ClassTag[],
    public graduatedAt?: Date,
  ) {}
  public static Build(c: IClass): Class {
    const managedBy = Staff.Build(c.managedBy);
    return new Class(
      c.id,
      c.name,
      c.type,
      managedBy,
      new Date(c.startDate),
      c.template,
      c.numberOfStudents,
      c.students,
      c.units,
      c.createdAt,
      c.updatedAt,
      c.status,
      c.effort,
      c.tags,
      c.graduatedAt ? new Date(c.graduatedAt) : undefined,
    );
  }
}
