import { Injectable } from '@angular/core';
import { SharedAppState } from '@libs/common/store/store.interface';
import { IAiEvaluationPayload } from '@libs/data/features/ai-evaluation/models/ai-evaluation.model';
import { StudentUnitContentAnswer } from '@libs/data/features/student/models/content/student-unit-content-answer.model';
import { IVideoTrackingPayload } from '@libs/data/features/student/models/video-tracking.model';
import { Store } from '@ngrx/store';
import { ViewerScope } from '../../enums/viewer-scope.enum';
import * as ViewerActions from '../actions/viewer.actions';
import {
  aiEvaluation,
  exitedFromLessonContents,
  isViewerPreviewMode,
  selectViewerState,
  type,
  viewerLesson,
  viewerUnit,
} from '../selectors/viewer.selectors';

@Injectable({ providedIn: 'root' })
export class ViewerFacade {
  public viewerState$ = this.store.select(selectViewerState);
  public isPreviewMode$ = this.store.select(isViewerPreviewMode);
  public unit$ = this.store.select(viewerUnit);
  public lesson$ = this.store.select(viewerLesson);
  public exitedFromLessonContents$ = this.store.select(exitedFromLessonContents);
  public aiEvaluation$ = this.store.select(aiEvaluation);
  public type$ = this.store.select(type);

  constructor(private readonly store: Store<SharedAppState>) {}

  public getUnitById(unitId: string, scope = ViewerScope.STUDENT): void {
    return this.store.dispatch(ViewerActions.getUnitById({ unitId, scope }));
  }

  public getLessonById(lessonId: string): void {
    return this.store.dispatch(ViewerActions.getLessonById({ lessonId }));
  }

  public setStudentLessonContentAnswer(
    unitId: string,
    lessonId: string,
    contentId: string,
    answerPayload: StudentUnitContentAnswer,
  ): void {
    return this.store.dispatch(
      ViewerActions.setStudentLessonContentAnswer({ unitId, lessonId, contentId, answerPayload }),
    );
  }

  public clearUnit(): void {
    this.store.dispatch(ViewerActions.clearUnit());
  }

  public updateVideoTracking(payload: IVideoTrackingPayload): void {
    this.store.dispatch(ViewerActions.updateVideoTracking({ payload }));
  }

  public setExitedFromLessonContents(exited: boolean): void {
    this.store.dispatch(ViewerActions.setExitedFromLessonContents({ exited }));
  }

  public getAiEvaluation(data: IAiEvaluationPayload): void {
    this.store.dispatch(ViewerActions.getAiEvaluation({ data }));
  }
}
